import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Stat from "../components/Cyberdash/Stat";
import DonutWrapper from "../components/Cyberdash/DonutWrapper";
import AreaWrapper from "../components/Cyberdash/AreaWrapper";
import BarWrapper from "../components/Cyberdash/BarWrapper";
import ResearchFeed from "../components/Cyberdash/ResearchFeed";
import BannerInfo from "../components/Cyberdash/BannerInfo";
import Spinner from "../components/base/Spinner";
import Header from "../components/base/Header";
import CriticalVulns from "../components/Cyberdash/CriticalVulns"

function Grid() {
  const { threat_intel, latest_feed, threat_dashboard, isLoading, isSuccess, isError, message } = useSelector(
    (state) => state.data
  )

  if (isError) {
    toast.error(message)
  }

  if (isLoading) {
    return <Spinner />
  }

  if (isSuccess && threat_dashboard && threat_intel && latest_feed) {
    return (
      <main>
        <Header text={"Global Cyber Intelligence Dashboard"} />
        <BannerInfo />
        <div className="Grid">
          <Stat data={threat_intel["1Stat"]} />
          <DonutWrapper data={threat_intel["1Donut"]} />
          <Stat data={threat_intel["2Stat"]} />
          <DonutWrapper data={threat_intel["2Donut"]} />
          <Stat data={threat_intel["3Stat"]} />
          <DonutWrapper data={threat_intel["3Donut"]} />
          <Stat data={threat_intel["4Stat"]} />
          <DonutWrapper data={threat_intel["4Donut"]} />
          {/* <AreaWrapper data={threat_intel["1AreaChart"]} /> */}
          <BarWrapper data={threat_intel['5BarChart']} />
          <BarWrapper data={threat_intel["1BarChart"]} />
          <BarWrapper data={threat_intel["2BarChart"]} />
          <BarWrapper data={threat_intel["3BarChart"]} />
          <BarWrapper data={threat_intel["4BarChart"]} />
          <DonutWrapper data={threat_intel["7Donut"]} id="icefall" />
          <DonutWrapper data={threat_intel["8Donut"]} />
          <ResearchFeed data={latest_feed["researchFeeds"]} />
          {/* <ResearchFeed data={threat_dashboard['1Latest']} /> */}
          {/* <CriticalVulns data={threat_dashboard['2Latest']} /> */}
        </div>
      </main>
    );
  }
}

export default Grid;
